/* @flow */
/*jshint esversion: 6 */
import React from "react";
import "../App.css";
import { Modal, Button } from "antd";
import PageToolbar from "./PageToolbar";

class GenericModal extends React.Component {

 
    render() {
        let a = document.getElementsByClassName("ant-modal-root")
        console.log("ant_modal", a?.length)
       
        let width = window.innerWidth > 600 && this.props.width ? this.props.width : "95%";
        let footer = this.props.footer ? this.props.footer : false
        let maskClosable = this.props.maskClosable ? this.props.maskClosable : false
        let title = (<PageToolbar
            {...this.props}
            isModal={true}
            actionButtons={this.props.actionButtons ?? []}
        />)

        let modalProps = { ...this.props, width, footer, title, maskClosable }

        return (<Modal  {...modalProps} />)
    }
}
export default GenericModal;