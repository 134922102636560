/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";
import ResponsiveTable from "./ResponsiveTable";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { InputNumber, Input, Button } from "antd";
import TableLineIcons from "./TableLineIcons";

type Props = {
    user: Object,
    visible: Boolean,
    onCancel: Function,
    DocumentNumber: Number,
    DocumentPOSID: Number,
    DocumentTypeId: Number,
    docTitles: Array,
}

class ModalDocumentLines extends Component {

    constructor(props: Props) {
        super(props);

        this.state = {
            linesList: [],
            editedRecord: {},
            editing: -1,
            filterText: "",
        }

        // this.id = "DOCUMENT_LINES_LIST";

        // if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate(prev) {
        if (this.props.visible && !prev.visible) {
            this.getLines();
        }
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    getLines = () => {
        const { DocumentNumber, DocumentPOSID, DocumentTypeId, targetDate } = this.props;

        this.sendAPI("get_document_lines", "mDocNumber\fmDocPos\fmDocType\fmTargetDate\r" + DocumentNumber + '\f' + DocumentPOSID + '\f' + DocumentTypeId + '\f' + (targetDate ?? ""), (ob) => {
            let lines = (ob.data) ? ob.data.split('\r') : [];
            this.setState({
                linesList: lines.map((i, index) => {
                    let line = i.split('\f');
                    return {
                        key: index,
                        itemCode: line[3],
                        itemBarcode: line[4],
                        description: line[5],
                        unitPriceNoTax: line[6],
                        unitPriceWithTax: line[7],
                        quantity: line[8],
                        discountPercentage: line[9],
                        totalSumNoTax: line[10],
                        totalSumWithTax: line[11],
                        taxId: line[12],
                        taxPercentageValue: line[13],
                        supplierId: line[14],
                        cost: line[15],
                        remarks: line[16],
                        unitID: line[18],
                        quantityInUnit: line[19],
                        unitDescription: '',
                        unitQuantity: line[20],
                        number: line[21]
                    }
                })
            })

        });
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let f = sorter.field;
        let isText = (f === 'itemCode' || f === 'itemBarcode' || f === 'description')
        this.setState({
            linesList: this.state.linesList.sort((a, b) => {
                if (sorter.order === undefined || sorter.order === "ascend") {
                    return isText ? a[f].localeCompare(b[f]) : a[f] - b[f]
                } else {
                    return isText ? b[f].localeCompare(a[f]) : b[f] - a[f]
                }
            })
        })
    }

    saveChangeLine = () => {
        const { DocumentNumber, DocumentTypeId, DocumentPOSID } = this.props;
        const { number, quantity, unitPriceNoTax, unitPriceWithTax, totalSumNoTax, totalSumWithTax, description, discountPercentage } = this.state.editedRecord
        let dataSend = "_docNum\f_docType\f_docPos\f_number\f_qty\f_unitNoTax\f_unitWithTax\f_totalNoTax\f_totalWithTax\f_description\f_discountPercentage\r"
            + DocumentNumber + "\f" + DocumentTypeId + "\f" + DocumentPOSID + "\f"
            + number + "\f" + quantity + "\f" + unitPriceNoTax + "\f" + unitPriceWithTax + "\f"
            + totalSumNoTax + "\f" + totalSumWithTax + "\f" + description + "\f" + discountPercentage
        this.sendAPI("save_change_doc_line", dataSend, (ob) => {
            this.setState({ editing: -1, editedRecord: {} }, () => {
                this.getLines()
                if (this.props.onEditTotal) this.props.onEditTotal(ob.data)
            })
        })
    }

    renderLineCol = (text, record, key) => {
        const { editing, editedRecord } = this.state;
        if (record.key == editing) {
            if (["unitPriceNoTax", "unitPriceWithTax", "quantity", "discountPercentage"].find(f => f == key)) {
                return (<InputNumber value={editedRecord[key]} onChange={e => {
                    let newRecord = { ...editedRecord, [key]: e }
                    switch (key) {
                        case 'unitPriceNoTax':
                            newRecord.unitPriceWithTax = ((e * ((100 + parseFloat(newRecord.taxPercentageValue)) / 100)) ?? 0).toFixed(2)
                            newRecord.totalSumNoTax = ((e * parseFloat(newRecord.quantity) * ((100 - parseFloat(newRecord.discountPercentage)) / 100)) ?? 0).toFixed(2)
                            newRecord.totalSumWithTax = ((parseFloat(newRecord.totalSumNoTax) * ((100 + parseFloat(newRecord.taxPercentageValue)) / 100)) ?? 0).toFixed(2)
                            break;
                        case 'unitPriceWithTax':
                            newRecord.unitPriceNoTax = ((e / ((100 + parseFloat(newRecord.taxPercentageValue)) / 100)) ?? 0).toFixed(2)
                            newRecord.totalSumWithTax = ((e * parseFloat(newRecord.quantity) * ((100 - parseFloat(newRecord.discountPercentage)) / 100)) ?? 0).toFixed(2)
                            newRecord.totalSumNoTax = ((parseFloat(newRecord.totalSumNoTax) / ((100 + parseFloat(newRecord.taxPercentageValue)) / 100)) ?? 0).toFixed(2)
                            break;
                        case 'quantity':
                            newRecord.totalSumNoTax = ((e * parseFloat(newRecord.unitPriceNoTax) * ((100 - parseFloat(newRecord.discountPercentage)) / 100)) ?? 0).toFixed(2)
                            newRecord.totalSumWithTax = ((e * parseFloat(newRecord.unitPriceWithTax) * ((100 - parseFloat(newRecord.discountPercentage)) / 100)) ?? 0).toFixed(2)
                            break;
                        case 'discountPercentage':
                            newRecord.totalSumNoTax = ((parseFloat(newRecord.quantity) * parseFloat(newRecord.unitPriceNoTax) * ((100 - e) / 100)) ?? 0).toFixed(2)
                            newRecord.totalSumWithTax = ((parseFloat(newRecord.quantity) * parseFloat(newRecord.unitPriceWithTax) * ((100 - e) / 100)) ?? 0).toFixed(2)
                            break;
                    }
                    this.setState({ editedRecord: newRecord })
                }} />)
            } else if (["description"].find(f => f == key)) {
                return (<Input value={editedRecord[key]} onChange={e => {
                    let newRecord = { ...editedRecord, [key]: e.target.value }
                    this.setState({ editedRecord: newRecord })
                }} />)
            } else {
                return editedRecord[key];
            }
        } else {
            return text
        }
    }

    renderEditLine = (text, record) => {
        const { editing } = this.state;
        if (record.key == editing) {
            return (<TableLineIcons
                onCheck={this.saveChangeLine}
                onClose={() => { this.setState({ editing: -1, editedRecord: {} }) }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.setState({ editing: record.key, editedRecord: record }) }}
                onDelete={() => { this.setState({ editedRecord: { ...record, quantity: 0, totalSumNoTax: 0, totalSumWithTax: 0 } }, this.saveChangeLine) }}
                deleteQuetion={{ title: this.getText(19901), okText: this.getText(19902), cancelText: this.getText(19903) }}
                genericIcons={[{ type: "form", onClick: () => { this.setState({ modalDocLineRemark: record }) }, tooltip: this.getText(20316) }]}
            />);
        }
    }


    render() {
        const { visible, onCancel, DocumentNumber, DocumentTypeId, DocumentIsClosed } = this.props;

        const formatter = new Intl.NumberFormat('he-IL', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        let gt = this.getText;
        let render = t => (<div style={{ textAlign: "right" }}>{t}</div>);
        let sorter = true;
        let dataSource = [...this.state.linesList];
        if (this.state.filterText) {
            let list = this.state.filterText.split(" ");
            let findText = (field) => {
                let res = true;
                list.forEach(x => { if (field.indexOf(x) === -1) res = false });
                return res;
            }
            dataSource = dataSource.filter(f => {
                return findText(f.itemCode)
                    || findText(f.itemBarcode)
                    || findText(f.description)
                    || findText(f.unitPriceWithTax)
                    || findText(f.quantity)
                    || findText(f.discountPercentage)
                    || findText(f.totalSumNoTax)
                    || findText(f.totalSumWithTax)
            })
        }

        let qtyDecimalCount = 0;

        dataSource.forEach(x => {
            let decimalNum = x.quantity?.split(".")[1];
            if (decimalNum) {
                if (parseInt(decimalNum[3]) && qtyDecimalCount < 4) { qtyDecimalCount = 4; return; }
                if (parseInt(decimalNum[2]) && qtyDecimalCount < 3) { qtyDecimalCount = 3; return; }
                if (parseInt(decimalNum[1]) && qtyDecimalCount < 2) { qtyDecimalCount = 2; return; }
                if (parseInt(decimalNum[0]) && qtyDecimalCount < 1) { qtyDecimalCount = 1; return; }
            }
        })

        const formatterQty = new Intl.NumberFormat('he-IL', {
            style: 'decimal',
            minimumFractionDigits: qtyDecimalCount,
            maximumFractionDigits: qtyDecimalCount,
        });

        let columns = [
            { title: gt(17075), key: "itemCode", dataIndex: "itemCode", width: "8%", sorter, render },
            { title: gt(17076), key: "itemBarcode", dataIndex: "itemBarcode", width: "8%", sorter, render },
            { title: gt(17077), key: "description", dataIndex: "description", width: "24%", sorter, render },
            { title: gt(19469), key: "unitPriceNoTax", dataIndex: "unitPriceNoTax", width: "10%", sorter, render: t => render(formatter.format(t)) },
            { title: gt(17078), key: "unitPriceWithTax", dataIndex: "unitPriceWithTax", width: "10%", sorter, render: t => render(formatter.format(t)) },
            { title: gt(17079), key: "quantity", dataIndex: "quantity", width: "8%", sorter, render: t => render(formatterQty.format(t)) },
            { title: gt(17080), key: "discountPercentage", dataIndex: "discountPercentage", width: "8%", sorter, render: t => render(formatter.format(t)) },
            { title: gt(17081), key: "totalSumNoTax", dataIndex: "totalSumNoTax", width: "8%", sorter, render: t => render(formatter.format(t)) },
            { title: gt(17082), key: "totalSumWithTax", dataIndex: "totalSumWithTax", width: "8%", sorter, render: t => render(formatter.format(t)) },
        ]

        if ((DocumentTypeId == 30 || DocumentTypeId == 31) && DocumentIsClosed == 0) {
            columns = columns.map(x => { return { ...x, render: (t, r) => this.renderLineCol(t, r, x.key) } })
            columns.push({ render: this.renderEditLine })
        }

        return (<GenericModal
            visible={visible}
            onCancel={() => {
                this.setState({
                    linesList: [],
                    editedRecord: {},
                    editing: -1,
                    filterText: "",
                    fixTotal: null,
                }, onCancel)
            }}
            title={gt(17072) + " " + DocumentNumber}
            showsearch={filterText => { this.setState({ filterText }) }}>
            {this.props.docTitles ?

                // (<Row>{
                // this.props.docTitles.map(x => (<Col span={window.innerWidth > 600 ? 8 : 24}><h3>{x.label}: {x.value}</h3></Col>))
                this.props.docTitles.map(x => (<h3 style={{ width: 400, display: "inline-block", verticalAlign: "text-top" }}>{x.label}: {x.value}</h3>))
                // }</Row>)
                : ""}
            <ResponsiveTable
                tableOnly
                dataSource={dataSource}
                onChange={this.handleTableChange}
                columns={columns} />
            <GenericModal
                visible={this.state.modalDocLineRemark != null}
                onCancel={() => { this.setState({ modalDocLineRemark: null }) }}
                title={this.getText(20317)}
                width={600}
                footer={[
                    <Button onClick={() => {
                        this.setState({ modalDocLineRemark: null })
                    }}>{this.getText(20319)}</Button>,
                    <Button type="primary" onClick={() => {
                        const { DocumentNumber, DocumentPOSID, DocumentTypeId } = this.props;
                        const { remarks, number } = this.state.modalDocLineRemark;
                        this.sendAPI("edit_doc_line_remarks", { DocumentNumber, DocumentPOSID, DocumentTypeId, remarks, number }, () => {
                            this.setState({ modalDocLineRemark: null }, this.getLines)
                        })
                    }}>{this.getText(20318)}</Button>,
                ]}
            >
                <Input.TextArea
                    value={this.state.modalDocLineRemark?.remarks}
                    onChange={e => { this.setState({ modalDocLineRemark: { ...this.state.modalDocLineRemark, remarks: e.target.value } }) }}
                />
            </GenericModal>
        </GenericModal>)
    }
}

export default ModalDocumentLines;

